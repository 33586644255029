*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans',sans-serif;
    
}
.about{
    width: 100%;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 78px 0px;
    background-color: #191919;
    background: linear-gradient(rgba(18, 22, 20, 0.726)100%,rgba(18, 22, 20, 0.7)100%), url('../../../public/images/support1.jpg');
   background-size: cover;
}

.about img{
    height: auto;
    width: 420px;
    /* object-fit:scale-down ; */
    border: 3px solid white;
    border-radius: 30px;
    /* box-shadow: 2px 2px 2px  #fcfcfc; */
    float: right;
    margin: 50px 0px 0px 20px;
    
}

.about text{
    width: 550px;
    display: flex; /* Flexbox modelini kullan */
    flex-direction: column; /* İçerikleri dikey olarak sırala */
    align-items: flex-end; /* İçerikleri sağa yasla */

}
.main{
    width: 1130px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: end !important;
}
.about-text h1{
color: white;
font-size: 80px;
text-transform: capitalize;
margin-bottom: 20px;
padding-left: 10px;
}
.about-text h3{
    color: #f9004d;
    padding-left: 20px;
    font-weight: 700;
    font-size: 26px;
}
.about-text h4{
    color: aqua;
}
.about-text ul{
   padding-left: 50px;
   color: #fcfcfc;
   letter-spacing: 1px;
   line-height: 28px;
   font-size: 18px;
   margin-bottom: 45px;
   margin-left: 20px;
}
.about-text h5 {
    color: white;
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 25px;
    padding-left: 15px;
}
span{
color: #f9004d;
}

.about-text p{
    color: #fcfcfc;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 20px;
    text-align: justify;
}

 /* button.aboutBtn{
    background: #B30034;
    color: white;
    text-decoration: none;
    border: 2px solid transparent;
    font-weight: bold;
    padding: 13px 30px;
    border-radius: 30px;
    transition: .4s;
    animation-duration: 100ms;
}
button.aboutBtn:hover{
  
  
    background: #00bcd4;
} */

.about-text a{
    display: flex;
    justify-content: end;
}
button.aboutBtn {
    background: rgba(255, 255, 255, 0.1); /* Yarı saydam beyaz arka plan */
    color: white;
    text-decoration: none;
    border: 2px solid rgba(255, 255, 255, 0.3); /* Yarı saydam beyaz çerçeve */
    font-weight: bold;
    padding: 13px 30px;
    border-radius: 30px;
    transition: .4s;
    animation-duration: 100ms;
    backdrop-filter: blur(10px); /* Cam efektini vermek için blur */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Hafif bir gölge */
    justify-content: end;
}

button.aboutBtn:hover {
    background: rgba(255, 255, 255, 0.2); /* Hover durumunda biraz daha az saydam */
    border: 2px solid rgba(255, 255, 255, 0.5); /* Hover durumunda çerçeve daha belirgin */
    color: rgb(127, 127, 127); /* Hover durumunda metin rengini değiştirin */
}


/* responsive */
@media(max-width:991px){


    .about{
        padding: 25px;
        width: 100%;
    }
   .about .main img{
        width: 100%;
    }
    .main{
        flex-direction: column;
        width: 100%;
    }
    .about-text{
        width: 100%;
    }
    .about-text h1{
        width: 100%;
       font-size: 3rem;
       padding-left: 10px;
    }
    button.aboutBtn {
        align-self: center; /* Mobil cihazlarda butonu merkeze al */
    }
    .about-text a{
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 290px){
    .about-text h1{
        width: 100%;
        font-size: 35px;
    }
}