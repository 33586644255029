*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    
}
.message-box{
    background: #f9004d;
    color: white;
    text-decoration: none;
    border: 2px solid transparent;
    font-weight: bold;
    padding: 13px 30px;
    border-radius: 30px;
    transition: .4s;
    text-align: center;
    align-items: center;

}
.message-box p{
    color: white;
    font-size: 12px;
    font-family: sans-serif;


}

.contact{
    position: relative;
    min-height: 100vh;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
background: linear-gradient(rgba(18, 22, 20, 0.726)100%,rgba(18, 22, 20, 0.7)100%), url('../../../public/images/contac.jpg');
 
 
    background-size: cover;

}

.contact .content{
    max-width: 800px;
    text-align: center;
   
}
.contact .content h2{
  
    color: white;
font-size: 60px;
text-transform: capitalize;
margin-bottom: 10px;
}
.contact .content p{
  
    font-weight: 300;
    color: #fff;
}
.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    
}

.container .contactInfo
{
    width: 50%;
    display: flex;
    flex-direction: column;
    
}
.container .contactInfo .box
{
    position: relative;
    padding: 20px 0;
    display: flex;
    
}
.container .contactInfo .box .icon{
    min-width: 60px;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    
}
.container .contactInfo .box .icon i{
    text-decoration: none;
    color: #333;
}
.container .contactInfo .box .icon:hover{
    transition: 0.3s;
    background-color: #f9004d;
    
}
.container .contactInfo .box .text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    color: #fff;
    flex-direction: column;
}
.container .contactInfo .box .text h3{
    font-weight: 500;
    color: #e91e63;
    font-size: 30px;
    text-transform: capitalize;
    font-family: sans-serif;
   
}
.contactForm{
    width: 40%;
    padding: 40px;
    background: #fff;
    border-radius: 30px;
   
}
.contactForm h2{
    font-size: 30px;
    color: #333;
    font-weight: 500;
}
.contactForm .inputBox{
    position: relative;
    width: 100%;
    margin-top: 10px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea
{
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
}
.contactForm .inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;

}
.contactForm .inputBox input:focus~span,
.contactForm .inputBox input:valid~span,
.contactForm .inputBox textarea:focus~span,
.contactForm .inputBox textarea:valid~span
{
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
}

.contactForm .inputBox input[type="submit"]{
    width: 100px;
    background: #e91e63;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    border-radius: 30px;
    transition: 0.3s;
}
.contactForm .inputBox input[type="submit"]:hover{
    background: #00bcd4;
    transition: 0.3s;
}

/* responsive sett */
@media (max-width:991px){
    .contact{
        padding: 50px;
    }
    .container{
        flex-direction: column;
    }
    .container .contactInfo{margin-bottom: 40px;}
    .container .contactInfo,
    .contactForm{
        width: 100%;
    }
}
@media screen and (max-width: 1024px){
    .container .contactInfo, .contactForm{
        width: 100%;
        min-width: 300px;
    }
}
@media screen and (max-width: 450px){
    .container .contactInfo, .contactForm{
        width: 100%;
        min-width: 300px;
    }
}
@media screen and (max-width: 320px){
    .container .contactInfo, .contactForm{
        width: 100%;
        min-width: 260px;
    }
}
/* @media screen and (max-width: 290px){
    .container .contactInfo, .contactForm{
        width: 100%;
        min-width: 260px;
    }
} */