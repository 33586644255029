*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  
}
.wrapper{
    font-family: 'raleway';
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #191919;
    /* background: url('../../../public/images/fon.jpg'); */
    background: linear-gradient(rgba(18, 22, 20, 0.726)100%,rgba(18, 22, 20, 0.7)100%), url('../../../public/images/services1.jpg');;
background-size: cover;
padding: 78px 0px;
}

.wrapper h1{
   
   color: white;
font-size: 80px;
text-transform: capitalize;
margin-bottom: 20px;
}
.content-box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1000px;
    margin-top: 50px;
    
}

.cardd{
    min-height: 220px;
    width: 320px;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    margin: 10px 4px;
    box-shadow: 0px 15px 25px rgba(0,0,0,0.2);
    transition: .4s;
   

}

.cardd i{
    margin: 20px;
    color: #191919;
    transition: 100ms;
}

.cardd h3{
    margin-bottom: 12px;
    font-weight: 400;
    text-align: center;
    color: #191919;
    text-decoration: none;
    transition: 100ms;
    font-size: 16px;
  
}

.cardd p{
    color: #6c757d;
    text-align: center;
    transition: 100ms;
}
.content-box.cardd:hover{
text-decoration: none;
transition: 4s;


}
.cardd:hover i,
.cardd:hover p{
    color: #fff;
    transition: 100ms;
}
.cardd:hover h3{
    font-weight: 600;
   border-bottom: 1px solid white;
   color: #fff;
   transition: 100ms;
  
}



.cardd:nth-child(1):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8)0%,
    rgba(255,0,82,0.8)100%),
    url("../../../public/images/fintech.jpg");
    background-size: cover;
    transition: 100ms;
   
}
.cardd:nth-child(2):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8)0%,
    rgba(255,0,82,0.8)100%),
    url("../../../public/images/crm.jpg");
    background-size: cover;
    transition: 100ms;
    
}
.cardd:nth-child(3):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8)0%,
    rgba(255,0,82,0.8)100%),
    url("../../../public/images/accounting.jpg");
    background-size: cover;
    transition: 100ms;
    
}
.cardd:nth-child(4):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8)0%,
    rgba(255,0,82,0.8)100%),
    url("../../../public/images/website.jpg");
    background-size: cover;
    transition: 100ms;
    
}
.cardd:nth-child(5):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8)0%,
    rgba(255,0,82,0.8)100%),
    url("../../../public/images/etrade2.jpg");
    background-size: cover;
    transition: 100ms;
    
}
.cardd:nth-child(6):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8)0%,
    rgba(255,0,82,0.8)100%),
    url("../../../public/images/support.jpg");
    background-size: cover;
    transition: 100ms;
    
}

/* Responsive */
@media(max-width:991px){
    .wrapper{
        padding: 25px;
    }
    .wrapper h1{
        font-size: 2.5em;
        font-weight: 600;
    }
    .content-box{
        flex-direction: column;
        width: 100%;
    }
    .cardd{
        min-width: 280px;
        margin: 10px auto;
        width: 100%;
    }

}
@media only screen and (max-width: 290px){
    
    .cardd{
        min-width: 270px;
        margin: 10px auto;
        width: 100%;
    }
  }

