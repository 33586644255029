
.lang-buttons{
    color:#fff;
    margin: auto;
    display: flex;
    font-size: medium;
}
.lang-buttons.active{
    color: #000;
    margin: auto;
    display: flex;
}

.langBtn{
    color: #00bcd4;
   
    transition: 0s;
    padding-right: 5px;
    border-bottom: 1px solid #fff;
}


.langBtn.active{
    
    transition: 0s;
    color: #00bcd4;
    border-bottom: 1px solid #000;
}
.langBtntr{
    color: #00bcd4;
   
    transition: 0s;
  
    padding-left: 5px;
    border-bottom: 1px solid #fff;
}
.langBtntr.active{
    transition: 0s;
    color: #00bcd4;
    border-bottom: 1px solid #000;
}