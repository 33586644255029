.navbar {
  width: 100%;
   background: #000;
   height: 80px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   position: sticky;
   top: 0;
   z-index: 999;
   transition: 0.3s;
 }
.nav-links.bg.active{
color: #00bcd4 !important;
transition: .3s;
}
.nav-links.bg{
color: #00bcd4 !important;
  transition: .3s;
}
.nav-links.bg.active:hover,.nav-links.bg{
  color: #00bcd4;
  transition: .3s;
}


 .nav-links.active{
  color: #000;
 }
 .nav-links.active:hover{
  color: #000 ;
 }
 /*Öncei hal */
 /* .nav-links{
   color: #fff;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0.5rem 1rem;
   height: 100%;
 } */

.active-page{
 border: 3px  red solid;
}
 .nav-links{
  display: flex;
 height: 100%;
  color: #fff;
 align-items: center;
  padding: 0.5rem 1rem;
  overflow: hidden;
  text-decoration: none;
  transition: .3s;
  position: relative;
 }
 .navbar.active{
   background: #fff;
   transition: 0.3s;
   animation: ease-in-out;
  
 }
 
 .nav-item>a:hover{
  color: #fff;
 }
 
 .navbarr-containerr {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 80px;
   max-width: 1500px;
 }
 
 .navbar-logo {
   color: rgb(221, 26, 26);
   justify-self: start;
   margin-left: 1px;
   cursor: pointer;
   text-decoration: none;
   display: flex;
   align-items: right;
   margin-bottom: 1rem;
  
 }
 
 img{
  object-fit: cover;
  width: 160px;
  height: 85px;
  
 
 }
 
 .fa-typo3 {
   margin-left: 0.5rem;
   font-size: 1.8rem;
 }
 
 .nav-menu {
   display: flex;
   grid-template-columns: repeat(5, auto);
   /* grid-gap: 10px; */
   list-style: none;
   text-align: center;
   width: 60vw;
   justify-content: end;
   margin-right: 2rem;
   max-width: 1200px;
 }
 
 /* for efect */
 .nav-links::before{
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  width: 50%;
  height: 2px;
  background: #fff;
  transition:.3s cubic-bezier(0.075, 0.82, 0.165, 1);
 }
 .nav-links::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 50%;
  height: 2px;
  background:  #fff;
  transition: .3s  cubic-bezier(0.075, 0.82, 0.165, 1);
 }
 
 .nav-links.active::before{
  background: #000;
 }


 .nav-links.active::after{
  background: #000;
 }


.nav-links:hover::before{
  right:0;
}
.nav-links:hover::after{
left:0;
}

 /*önceki hali için*/
 /* .nav-links:hover {
   border-bottom: 4px solid #fff;
   transition: all 0.2s ease-out;
 }
 .nav-links.active:hover {
   border-bottom: 4px solid rgb(14, 12, 12);
   transition: all 0.2s ease-out;
 }
  */

 
 .nav-links-mobile {
   display: none;
 }
 
 .menu-icon {
   display: none;
   
 }
 
 
 @media screen and (max-width: 960px) {
   .NavbarItems {
     position: relative;
    
   }
 
 
   .nav-menu {
     display: flex;
     flex-direction: column;
     width: 100%;
     height: auto;
     position: absolute;
     top: 80px;
     left: -100%;
     opacity: 1;
     transition: all 0.5s ease;
     /* background: rgb(228, 4, 4); */
   }
 
 .nav-item{
  position: relative;
  display: flex;
  align-items: center;
 
  
 }

   .nav-menu.active {
     /* background: #242222; */
     background: #000;
     left: 0;
     opacity: 1;
 height: 900px;
 padding-bottom: 550px;
     transition: all 0.5s ease;
     z-index: 1;
     padding-right: 32px;
     
   }
  .nav-menu.gr.active{
    background: #fff;
  }
 
   .nav-links {
     text-align: center;
     padding: 1rem;
   
     width: 100%;
     display: table;
   }
 
   .nav-links:hover {
    
     color: #242424;
     border-radius: 0;
   }
 
   .navbar-logo {
     position: absolute;
     top: 0;
     left: 0;
     display: flex;
    
 
     
     transform: translate(10%, -7%);
   }
 
   .menu-icon {
     display: block;
     position: absolute;
     top: 0;
     right: 0;
     transform: translate(-100%, 60%);
     font-size: 1.8rem;
     cursor: pointer;
   }
   



   .fa-times {
     
     color: #fff;
     font-size: 2rem;
   }
 
   .fa-bars{
    color: #fff;
   }
   .fa-times.active{
    color: #000;
   }
   .fa-bars.active{
    
    color: #000;
   }
 
   .nav-links-mobile {
     display: block;
     text-align: center;
     margin: 2rem auto;
     border-radius: 4px;
     width: 80%;
     text-decoration: none;
     font-size: 1.5rem;
     background-color: transparent;
     /* color: #242222; */
     padding: 14px 20px;
    
     transition: all 0.3s ease-out;
     
   } 
 }

 