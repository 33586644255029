@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
 
}

/* SWİPER STYLE */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background-color: rgba(255, 255, 255, 0.6); */
  /* border-radius: 5%; */
  /* border: 1px solid gray; */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}

  .swiper-slide img {
    /* display: block; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 576px) {
  .slider.swiper-slide img {
      height: auto; /* Yüksekliği orijinal boyutunda tut */
      min-height: 213px;
      max-height: 260px;
      object-fit: fill;
  }
  }

  @media (max-width: 576px) {
  .slider-two.swiper-slide img {
      height: auto; /* Yüksekliği orijinal boyutunda tut */
      min-height: 213px;
      max-height: 260px;
      object-fit: fill;
  }
  }


.swiper-pagination-bullet {
  display: none !important;
}




