.cards {
  padding: 4rem;
  background: #fff;
  /* text-align: center; */
 
}


.cards>h1 {
   text-align: center;
   background: #f9004d;
   padding: 10px;
   color: #fff;
   border-radius: 30px;
   font-size: 28px;
   width:25% ;
 margin: auto;
 
 

}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #f9004d;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }

}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
   
    min-width: 300px;
    margin: 10px auto;
  }
  .cards h1{width: 100%;
    background: #f9004d;
    padding: 10px;
    color: #fff;
    border-radius: 30px;
    font-size: 28px;
   
  }
  .cards__items{
   padding-right: 2rem !important;
  }
}
@media only screen and (max-width: 290px){
  .cards h1{
    font-size: 20px;
  }
  .cards__item{
    min-width: 270px;
    margin: 10px auto;
    padding-right: 2rem;
  }
  .cards__items{
    padding-right: 2rem !important;
   }

   .imgLogo{
    margin-top: 50px !important;
   }
}