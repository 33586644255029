.references{
    font-family: 'raleway';
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #191919;
    /* background: url('../../../public/images/fon.jpg'); */
    background: linear-gradient(rgba(18, 22, 20, 0.726)100%,rgba(18, 22, 20, 0.7)100%), url('../../../public/images/references.jpg');;
background-size: cover;
padding: 78px 0px;
}

.card.refCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 22rem;
    max-height: 17rem;
    border-radius: 25px !important;
    padding: 40px !important;
    transition: 0.3s;
   
    
}
.card.refCard:hover{
    transform: scale(1.04);
    transition: 0.3s;
}

.logo{
 height: 150px;
 object-fit: contain;
    

    
}



@media (max-width:991px){
    .card.refCard{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        max-height: 500px !important;
        height: 100%;
       
        padding: 0px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}






@media only screen and (max-width: 290px){
    
    .card.refCard{
        max-width: 200px;
        padding: 10px !important;
    }
  }
