.content-load{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  padding: 0 0 ;
    background: #000;

    /* animation: cont-back 2s; */
}

/* @keyframes cont-back{
 from {
  opacity: 1;
  
 }
 to{
  opacity: 0;
  
 }

} */
.loader{
height: 200px;
width: 300px;

 display: flex;
    flex-direction: column;
   background-image: url(../logo2.png);
   background-color: #000;
   border: #000 1px solid;
   background-size: contain;

   background-position: center;
   background-repeat: no-repeat;
 background-size: cover;
   position: relative;
   animation: mymove 3s ;
   animation-direction: alternate ;
  
   




   

   
}
@keyframes mymove {
  0% {width:  0px;}
   100%{width: 300px;}


  }
    
  
 .content-load h1{
 text-align: center;
 color: #fff; 
 font-family: Arial, Helvetica, sans-serif;
 
}
.content-load h1 span{
   color:#f9004d ; 
}
@media(max-width:900px)
{
    .content-load{
     
        width: 100%;
    }
    .loader{
        width: 100%;
   
      
        background-size: cover;
        
    }
   
}

